/* You can add global styles to this file, and also import other style files */
body {
  margin: 0px;
  padding: 0px;
  background-color: var(--kit-color-background);
  font-family: "Open Sans", sans-serif;
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans");

@font-face {
  font-family: "Open+Sans";
  src: url("https://fonts.googleapis.com/css2?family=Oxygen&display=swap");
}

nz-layout {
  background-color: #fff;
}

.background-container {
  background-color: #fff;
}


.mr-2 {
  margin-right: 8px;
}

.mr-4 {
  margin-right: 16px;
}

.ml-2 {
  margin-left: 8px;
}

.ml-4 {
  margin-left: 16px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-4 {
  margin-top: 16px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mt-2 {
  margin-bottom: 8px;
}

.mt-4 {
  margin-bottom: 16px;
}

.pl-2 {
  padding-left: 8px;
}

.pl-4 {
  padding-left: 16px;
}

.pr-2 {
  padding-right: 8px;
}

.pr-4 {
  padding-right: 16px;
}

.pb-4 {
  padding-bottom: 16px;
}

.pb-2 {
  padding-bottom: 8px;
}

.pt-2 {
  padding-top: 8px;
}
.pt-4 {
  padding-top: 16px;
}



.NgxEditor__MenuBar{
  padding: 8px !important;
}

nz-tab-nav-operation{
  width: 72px !important;
}

.ant-input-number-disabled{
  color: black;
}
